import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { IconButton, ListItemSecondaryAction, ListItemText, Typography, styled } from "@mui/material";
import { TFunction } from "i18next";
import { useDefaultCurrency } from "../../domain/currencies";
import { useGetDynamicFieldLabel } from "../../hooks/useGetDynamicFieldLabel";
import DndItem from "../draggable-list/DndItem";
import { OrderableColumnsRelation } from "./TableColumnConfigForm";

interface ITableColumnConfigColumnItemProps {
    item: OrderableColumnsRelation;
    translate: TFunction;
    translationPrefix?: string;
    onRemove: (column: string) => void;
    isDefault: boolean;
    visibleOnHoverClass?: string;
    isColumnDisabled?: boolean;
    disabled?: boolean;
}

const StyledDndItem = styled(DndItem)(({ theme }) => ({
    padding: theme.spacing(0.5, 2),
}));

const TableColumnConfigColumnItem = ({
    item,
    translate,
    onRemove,
    isDefault = false,
    visibleOnHoverClass,
    translationPrefix,
    isColumnDisabled = false,
    disabled = false,
}: ITableColumnConfigColumnItemProps) => {
    const clientCurrency = useDefaultCurrency();
    const { getDynamicColumnLabel } = useGetDynamicFieldLabel();

    return (
        <StyledDndItem id={item.id} divider showHandle={!disabled}>
            <ListItemText>
                <Typography>
                    {getDynamicColumnLabel(
                        translationPrefix !== undefined && isDefault ? `${translationPrefix}.${item.name}` : item.name,
                        clientCurrency.isoCode,
                    )}
                </Typography>
            </ListItemText>
            {!disabled && !isColumnDisabled && (
                <ListItemSecondaryAction className={visibleOnHoverClass}>
                    <IconButton size="small" onClick={() => onRemove(item.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </StyledDndItem>
    );
};

export default TableColumnConfigColumnItem;
