import { ExportLocale, Language } from "api-shared";
import { useCallback, useMemo } from "react";

export const useTranslateLocale = (targetLanguage: Language) => {
    const languageDisplayName = useMemo(() => new Intl.DisplayNames([targetLanguage.toString()], { type: "language" }), [targetLanguage]);

    const translateLocale = useCallback(
        (locale: ExportLocale | Language) => {
            return languageDisplayName.of(locale);
        },
        [languageDisplayName],
    );

    return { translateLocale };
};
